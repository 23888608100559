import React, { useState } from "react";
import styled from "styled-components";
import { Logo, RouterLink, HideOnDesktop } from "./Typography";
import hamburgerIcon from "./img/assets/hamburger.png";
import linkedin from "./img/assets/linkedin.svg";
import twitter from "./img/assets/twitter.svg";
import closeIcon from "./img/assets/close.svg";
import HeaderLink from "./HeaderLink";
import { only } from "styled-breakpoints";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenuOpenState = () => setMenuOpen(!menuOpen);

  return (
    <Root>
      <RouterLink to="/">
        <Logo />
      </RouterLink>
      <HideOnDesktop>
        <div style={{ position: "absolute", zIndex: 999 }}>
          <HeaderMenuButton onClick={toggleMenuOpenState}>
            <HeaderMenuButtonImage
              src={menuOpen ? closeIcon : hamburgerIcon}
              alt={menuOpen ? "close menu" : "open menu"}
            />
          </HeaderMenuButton>
        </div>
      </HideOnDesktop>
      <HeaderLinks onClick={toggleMenuOpenState} visible={menuOpen}>
        <HeaderLink href="/#about" text="ABOUT" />
        <HeaderLink href="/#science" text="SCIENCE" />
        <HeaderLink href="/#team" text="TEAM" />
        <HeaderLink href="/press" text="PRESS" />
        <HeaderLink href="/papers" text="PAPERS" />
        <HeaderLink href="/#contact" text="CONTACT" />
        <HeaderLink
          href="https://twitter.com/geoquant"
          icon={twitter}
          target="_blank"
        />
        <HeaderLink
          href="https://www.linkedin.com/company/geoquant/"
          icon={linkedin}
          target="_blank"
        />
      </HeaderLinks>
    </Root>
  );
};

const Root = styled.div`
  background-color: #041c38;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  width: 100%;
  padding: 0 2.5rem;
  z-index: 99;
`;

const HeaderMenuButton = styled.button`
  display: flex;
  align-items: center;
  background-color: unset;
  border: unset;
  position: relative;
`;

const HeaderMenuButtonImage = styled.img`
  position: absolute;
  right: 0;
  height: 20px;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
`;

const HeaderLinks = styled.div`
  display: flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 700;

  ${only("mobile")} {
    left: ${({ visible }) => (visible ? 0 : "100vw")};
    background-color: #041c38;
    position: absolute;
    top: 40px;
    width: 100%;
    height: calc(100vh - 40px);
    flex-direction: column;
    padding-top: 25px;
    transition: left .3s ease-in, visibility .3s ease-in;
`;

export default Header;
