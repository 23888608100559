import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Press from "./Press";
import Data from "./Data";
import Home from "./Home";
import Papers from "./Papers";
import Embed from "./Embed";
import styled, { ThemeProvider } from "styled-components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import FormSubmitted from "./FormSubmitted";

const theme = {
  breakpoints: {
    mobile: "0px",
    desktop: "425px", //todo: add tablet support (426px - desktop)
  },
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Root>
          <Header />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/press">
              <Press />
            </Route>
            <Route exact path="/data">
              <Data />
            </Route>
            <Route exact path="/papers">
              <Papers />
            </Route>
            <Route exact path="/form-submitted">
              <FormSubmitted />
            </Route>
            <Route exact path="/embed">
              <Embed />
            </Route>
            <Redirect from="*" to="/" />
          </Switch>
          <Footer />
        </Root>
      </Router>
    </ThemeProvider>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #041c38;
`;

export default App;
