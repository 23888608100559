import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Space5 } from "./Typography";
import linkedin from "./img/assets/linkedin.svg";
import dan from "./img/team/Dan.jpg";
import data from "./img/team/DATA.jpg";
import eilam from "./img/team/Eilam.jpg";
import eliad from "./img/team/Eliad.jpg";
import cory from "./img/team/Cory.png";
import kathryn from "./img/team/Kathryn.png";
import tsveta from "./img/team/tsveta.png";
import iris from "./img/team/iris-malone.jpg";
import aycan from "./img/team/aycan.png";
import mark from "./img/team/Mark.jpg";
import ross from "./img/team/Ross.jpg";
import shai from "./img/team/Shai.jpg";
import jean from "./img/team/jean_maloney.png";
import miguel from "./img/team/miguel_brito.png";
import arjun from "./img/team/arjun-kapur.png";
import { Space2 } from "./Typography";
import useBreakpoint from "./useBreakpoint";
import { up, only } from "styled-breakpoints";

const Members = () => {
  return (
    <Root>
      <Cards>
        {team.map((member) => (
          <MemberContainer key={member.name} member={member} />
        ))}
      </Cards>
      <Space2 />
    </Root>
  );
};

const MemberContainer = (props) => {
  const breakpoint = useBreakpoint();

  if (breakpoint === "mobile") {
    return <MobileMemberContainer {...props} />;
  }

  return <DesktopMemberContainer {...props} />;
};

const DesktopMemberContainer = ({ member }) => {
  const link = member.linkedin || member.link;
  const target = member.target || "_blank";

  return (
    <a href={link} target={target} rel="noopener noreferrer">
      <Container>
        <Member member={member} />
      </Container>
    </a>
  );
};

const MobileMemberContainer = ({ member }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Container
      onClick={() => {
        if (!isVisible) {
          setIsVisible(true);
          return;
        }

        window.open(member.linkedin || member.link, member.target || "_blank");
      }}
    >
      <Member member={member} isVisible={isVisible} />
    </Container>
  );
};

const Member = ({ member, isVisible }) => {
  return (
    <Card>
      <CardInfo isVisible={isVisible}>
        <CardName>{member.name}</CardName>
        <Space5 />
        {member.title && <CardTitle>{member.title}</CardTitle>}
        {member.linkedin && (
          <CardLinkedin>
            <img src={linkedin} alt={member.name} />
          </CardLinkedin>
        )}
      </CardInfo>
      <CardImage src={member.image} isVisible={isVisible} />
    </Card>
  );
};

const Root = styled.div``;

const Container = styled.div`
  margin: 0 15px 30px 15px;
  max-width: 14rem;
  ${only("mobile")} {
    width: 40%;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Card = styled.div`
  position: relative;
  color: #fff;
  border: 1px solid #ff6363;
`;

const CardInfo = styled.div`
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0.5rem;
  background-color: #ff6363;
  transform: translateY(10px);
  ${up("desktop")} {
    padding: 1rem;
    ${Card}:hover & {
      opacity: 1;
      transform: translateY(0);
    }
  }

  ${(props) =>
    props.isVisible &&
    css`
      opacity: 1;
      transform: translateY(0);
    `}
`;

const CardImage = styled.img`
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transform: translate(-10px, 10px);
  z-index: 1;
  width: 100%;

  ${up("desktop")} {
    ${Card}:hover & {
      transform: translate(0, 0);
      opacity: 0;
      z-index: -1;
    }
  }

  ${(props) =>
    props.isVisible &&
    css`
      transform: translate(0, 0);
      opacity: 0;
      z-index: -1;
    `}
`;

//todo: refactor 425px into a variable
const CardName = styled.div`
  font-size: 1.5rem;
  font-weight: bold;

  ${only("mobile")} {
    font-size: 1rem;
  }
`;

const CardTitle = styled.div`
  font-size: 1rem;
  ${only("mobile")} {
    font-size: 0.75rem;
  }
`;

const CardLinkedin = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  ${only("mobile")} {
    right: 0.25rem;
    bottom: 0.25rem;
  }
`;

const team = [
  {
    name: "DATA",
    image: data,
    link: "/data",
    target: "_self",
  },
  {
    name: "Mark Rosenberg, PhD",
    title: "Co-Head and Founder",
    image: mark,
    linkedin: "https://www.linkedin.com/in/mark-y-rosenberg/",
  },
  {
    name: "Dan Tawfik",
    image: dan,
    title: "Co-Head",
    linkedin: "https://www.linkedin.com/in/daniel-tawfik-956a7576/",
  },
  {
    name: "Ross Schaap, PhD",
    title: "Head of Research",
    image: ross,
    linkedin: "https://www.linkedin.com/in/ross-schaap-9149394b/",
  },
  {
    name: "Cory Baird, PhD",
    title: "Senior Analyst, Political Science | Data Modeling",
    image: cory,
    linkedin: "https://www.linkedin.com/in/cory-baird-3ab06541/",
  },
  {
    name: "Kathryn Wainfan, PhD",
    title: "Senior Analyst, Political Science | Research Management",
    image: kathryn,
    linkedin: "https://www.linkedin.com/in/kathryn-wainfan-phd-a78972183/",
  },
  {
    name: "Iris Malone, PhD",
    title: "Director, Political Science | Data Modeling",
    image: iris,
    linkedin: "https://www.linkedin.com/in/iris-malone/",
  },{
    name: "Aycan Gamache, PhD",
    title: "Senior Analyst, Political Science | Data Modeling",
    image: aycan,
    linkedin: "https://www.linkedin.com/in/aycan-gamache/",
  },
  {
    name: "Tsveta Petrova, PhD",
    title: "Senior Analyst, Political Science | Research Management",
    image: tsveta,
    linkedin: "https://www.linkedin.com/in/tsveta-petrova-4805239/",
  },
  {
    name: "Jean Maloney",
    title: "Senior Associate | Product Specialist",
    linkedin: "https://www.linkedin.com/in/jeanmaloney/",
    image: jean,
  },
  {
    name: "Miguel Brito",
    title: "Director | Product Specialist",
    linkedin: "https://www.linkedin.com/in/miguel-brito-977a1716/",
    image: miguel,
  },
  {
    name: "Arjun Kapur",
    title: "Director | Product Specialist",
    linkedin: "https://www.linkedin.com/in/arjunrosskapur/",
    image: arjun,
  },
  {
    name: "Shai Kerer",
    title: "Head of Technology",
    image: shai,
    linkedin: "https://www.linkedin.com/in/shai-kerer-96a17b23/",
  },
  {
    name: "Eilam Bulka",
    title: "Head of Frontend Development",
    image: eilam,
    linkedin: "https://www.linkedin.com/in/eilam-bulka-1979b418/",
  },
  {
    name: "Eliad Levy",
    title: "Data Science & Machine Learning",
    image: eliad,
    linkedin: "https://www.linkedin.com/in/eliadlevy/",
  },
];

export default Members;
