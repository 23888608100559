import styled, { keyframes } from "styled-components";
import React, { useMemo, useCallback } from "react";
import {
  H2,
  H4,
  H6,
  Space1,
  Space2,
  Space4,
  PageContainer,
  Paragraph3,
} from "./Typography";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import ContactForm, { postContactForm } from "./ContactForm";
import { only, up } from "styled-breakpoints";
import { isEmpty } from "lodash/fp";

const FORM_DATA_KEY = "form_data";

const loadForm = () => {
  const item = localStorage.getItem(FORM_DATA_KEY);
  return item ? JSON.parse(item) : {};
};

const saveForm = (formData) => {
  localStorage.setItem(FORM_DATA_KEY, JSON.stringify(formData));
};

const Papers = React.memo(() => {
  const formDataCache = useMemo(() => {
    return loadForm();
  }, []);

  const navigateSuccess = useCallback((paper) => {
    window.location.href = "/form-submitted";
  }, []);

  return (
    <PageContainer>
      <Content>
        <Space1 />
        <H2>Papers</H2>
        <Space1 />
        {papers.map((paper) => {
          // reactjs-popup expects the trigger button to be an Element, not a component,
          // so we must create this trigger button from within the map() loop
          const PaperButton = (
            <StyledPaper>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <H4>{paper.title}</H4>
                <Date>
                  <Paragraph3>{paper.date}</Paragraph3>
                </Date>
              </div>
              <Space4 />
              {paper.description && (
                <Paragraph3>{paper.description}</Paragraph3>
              )}
              <Space2 />
            </StyledPaper>
          );

          if (!isEmpty(formDataCache)) {
            return (
              <div
                key={paper.title}
                onClick={() => {
                  const newFormData = {
                    ...formDataCache,
                    extraData: paper,
                  };
                  postContactForm(newFormData, "papers")
                    .then(() => {
                      navigateSuccess();
                    })
                    .catch((e) => {
                      console.error("cannot re-post contact form", e);
                    });
                }}
              >
                {PaperButton}
              </div>
            );
          }

          return (
            <StyledPopup
              key={paper.title}
              trigger={PaperButton}
              position="center center"
              modal
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "1rem 0",
                  }}
                >
                  <H6>
                    Please fill in your info to receive the paper by email
                  </H6>
                </div>
                <ContactForm
                  shouldAcceptTerms
                  onSubmitSuccess={(formWithExtraData) => {
                    saveForm(formWithExtraData);
                    navigateSuccess(paper);
                  }}
                  extraData={paper}
                  source="papers"
                />
              </div>
            </StyledPopup>
          );
        })}
        <Space1 />
      </Content>
    </PageContainer>
  );
});

const StyledPaper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 4rem;
  cursor: pointer;
`;

const Content = styled.div`
  max-width: 800px;
  ${up("desktop")} {
    margin-left: 180px;
  }
`;

const Date = styled.div`
  margin-left: 1rem;
`;

const animation = keyframes`
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5) !important; //todo: remove !important hack;
  }

  &-content {
    animation: ${animation} 0.3s;
    background: #041c38;
    min-width: 500px;
    min-height: 600px;
    border: none;
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 1) !important; //todo: remove !important hack
    margin-top: 150px;

    ${only("mobile")} {
      margin-top: 100px;
      min-width: 95vw;
      min-height: 100vh;
      border: 1px solid #fff;
    }
  }
  &-arrow {
    display: none;
  }
`;

const papers = [
  {
    title: `State Street Insights “In Practice”: GeoQuant’s FX Political Risk Indicator`,
    date: "Aug 2023",
    description: `With the rising influence of political and geopolitical risk on market dynamics and economic trends, a systematic approach to measuring and monitoring political risk can help investors improve risk-adjusted performance. To better capture trends in political risk, State Street has partnered with GeoQuant, a Fitch Solutions company, to offer political risk indices tailored to the foreign exchange markets.`,
    url: "/GeoQuant_InPractice.pdf",
  },
  {
    title:
      "Politics Matters: Modeling Sovereign Yields as a Function of Political Risk",
    date: "May 2022",
    description: `GeoQuant’s suite of political risk indicators overcomes traditional limitations in political risk analysis by quantifying and tracking risk at higher frequency than industry-standard practice. We theorize that political risk — and underlying core domestic political dynamics — influences sovereign credit market behavior systematically in ways that are reflected in sovereign yields. We further theorize that these effects are conditional on the level of a country’s financial market development (e.g., DM, EM).`,
    url: "/GeoQuant_Bond_Model_Paper_v2.pdf",
  },
  {
    title:
      "Political Risk and Emerging Market Equities: Applications in an Index Framework",
    description: `Political risk is widely presumed to affect emerging market equities, but its impact has historically been difficult to assess due to the lack of quantifiable, systematic, and standardized political risk metrics. In partnership with S&P, this paper devises and back-tests an Emerging Markets Political Risk-Tilted Concept Index. The paper demonstrates that systematically incorporating political risk as a factor into emerging market equity allocation decisions can drive outperformance relative to the S&P Emerging BMI benchmark.`,
    url: "/GeoQuant_S_P_WhitePaper.pdf",
    date: "April 2021",
  },
  {
    title:
      "A Political Risk‐Augmented Early Warning Signal for Monthly Fx Returns, with Comparison to Citi Early Warning Signal (EWS)",
    description: `GeoQuant uses a custom “Macro-Social” political risk indicator to compare the relative performance of political risk and economic/financial risk – as proxied by the Citi (EWS) – as predictors of local currency weakness for a panel of 23 countries from 2013-2019. [The paper complements a CitiFX Quant in Focus back-test of GeoQuant data (Kasikov 2021) authored by CitiBank]`,
    url: "/forecastmodel_fx_macrosocial_ews.pdf",
    date: "April 2021",
  },
  {
    title: "Year Ahead 2021",
    url: "/GeoQuant2021YearAhead.pdf",
    date: "Jan 2021",
    description: `GeoQuant’s annual predictions and forecasts for political risk in the year ahead, including; political risk trending in emerging and developed markets, global social instability risk, bilateral geopolitical risk, political risk in currency markets and last year’s election calls in review.`,
  },
  {
    title: "A Political Risk Forecast Model for Quarterly GDP Growth",
    url: "/forecastmodel_gdpgrowth_v1.pdf",
    date: "Feb 2021",
    description: `We leverage the high-frequency nature of our data to assess the impact of political risk on quarterly GDP growth using an extensive battery of standard econometric models. We find that political risk is negatively associated with quarter-on-quarter (Q/Q) GDP growth for a sample of 48 countries over 2013-2019, including all of the G20 and a subset of emerging and frontier markets. Findings are robust to a wide variety of different models specifications. To the best of our knowledge, this paper provides the most direct and empirically robust analysis of political risk’s impact on growth to date.`,
  },
  {
    title: "GeoQuant Covid Update",
    url: "/GeoQuant_CovidUpdate.pdf",
    date: "Aug 2020",
    description: `GeoQuant’s Health and Covid-19 Political Risk indicators continue to perform strongly as proxies for global health outcomes and related macro risks, reinforcing the findings of our recent Covid-19 white paper.`,
  },
  {
    title: "GeoQuant White Paper Covid-19",
    url: "/GeoQuant_White_Paper_Covid-19.pdf",
    date: "May 2020",
    description: `The Covid-19 pandemic makes the need for GeoQuant’s high-frequency country risk data especially clear, as investors try to assess a wide range of economic, social and political factors in a highly fluid, dynamic environment. Structural factors still clearly matter, but it is the interaction of rapidly-evolving current events filtered through political structures that is driving cross-country differences in health, economic and broader social risks from Covid-19. This paper examines those differences and their impact on markets.`,
  },
  {
    title: "Year Ahead",
    url: "/Geoquant_Year_Ahead_2020.pdf",
    date: "Jan 2020",
    description: `GeoQuant’s annual predictions and forecasts for political risk in the year ahead, including; political risk trending in emerging and developed markets, global social instability risk, bilateral geopolitical risk, political risk in currency markets and last year’s election calls in review.`,
  },
  {
    title: "A Politcal Risk Trading Strategy for the Forex Market",
    date: "October 2019",
    description: `GeoQuant uses a representative subset of our political risk indicators and a risk-derived MACD model to devise a political risk trading strategy for the Forex market, and subsequently back-test the strategy across 33 countries with floating exchange rate regimes from June 2013 – June 2019 using eight of our core risk indicators. We find that a trading model that incorporates political risk inputs outperforms a simple buy-hold strategy in the majority of the countries we analyze.`,
    url: "/FXWhitePaper.pdf",
  },
  {
    title: "Forecast Models of Sovereign Bond Rates",
    date: "December 2018",
    description: `GeoQuant develops a simple statistical model using our daily top-line Political Risk indicators to forecast daily yields on ten-year bonds for twenty countries. Tests show that, when optimized to country-specific factors, risk signals from the model predict the direction of market movements an average of 74% of the time.`,
    url: "/GeoQuant_Whitepaper_Sovereign_Debt.pdf",
  },
];

export default Papers;
